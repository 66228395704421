.react-datepicker {
  width: 100%;
  font-size: 1.6rem;
  color: #585757;

  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;

  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 1px 18px rgba(0, 0, 0, 0.06), 0px 6px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  padding: 16px 8px;

  font-family: 'Inter', sans-serif !important;
}
.react-datepicker__tab-loop {
  z-index: 2;
}
.react-datepicker__navigation {
  /*height: 1.2rem;*/
  /*width: .6rem;*/
  position: absolute;
}
.react-datepicker__navigation--previous {
  top: 1rem;
  left: 2.4rem;
}
.react-datepicker__navigation--next {
  top: 1rem;
  right: 2.4rem;
}

.react-datepicker__header {
  background: none;
  border: none;
  padding-top: 0;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 1.6rem;
  color: #1c1c1c;
  font-weight: 500;
  margin-bottom: 1.6rem;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-size: 1.6rem;
  color: #585757;
  line-height: 2.4rem;
  width: 3.6rem;
  height: 3.6rem;
}
.react-datepicker__day {
  padding: 0.6rem;
  font-weight: 400;
  margin: 0;
}

.react-datepicker__day:hover {
  border-radius: 8px;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__day--in-range:hover {
  background: linear-gradient(0deg, rgba(0, 133, 255, 0.1), rgba(0, 133, 255, 0.1)), #ffffff;
  border-radius: 0;
}
.react-datepicker__day--selected,
.react-datepicker__day--range-end,
.react-datepicker__day--selected:hover,
.react-datepicker__day--range-end:hover,
.react-datepicker__day--in-selecting-range:hover {
  background-color: #0085ff;
  color: #fff;
  border-radius: 8px;
}

/*styles added to month range datepicker*/
.react-datepicker__month-text {
  padding: 1rem 2.2rem;
  width: auto;
  margin: 2px 0 !important;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__month-wrapper {
  width: 100%;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  width: auto;
}

.react-datepicker__month--in-range {
  border-radius: 0 !important;
}

.react-datepicker__month-text--keyboard-selected {
  border-top-right-radius: 0.3rem !important;
  border-bottom-right-radius: 0.3rem !important;
}

.react-datepicker__month--in-selecting-range {
  background-color: lightblue !important;
}
