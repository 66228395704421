@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Bold.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.woff') format('woff');
  font-weight: 400;
}

.number-input {
  width: 100%;
  font-family: Inter, sans-serif;
  font-weight: 400;
  color: #585757;
  background-color: #fff !important;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 0.8rem 1.2rem;
  height: 40px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
}

.number-input:focus {
  outline: none;
  border: 1px solid #0085ff;
}

.number-input:disabled {
  opacity: 0.6;
}

.number-input::placeholder {
  color: #969696;
}

.number-input-error {
  border: 1px solid #e92c2c !important;
}

.Toastify__toast {
  font-family: Inter, sans-serif !important;
  font-size: 14px;
}


.datepicker-error{
  border: 1px solid #e92c2c !important;
}
